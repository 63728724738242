var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-form",
        {
          attrs: {
            readonly: !_vm.me.is.superAdmin && !_vm.me.is.transportationAdmin,
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [_vm._v("Notifications")]),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-expansion-panels",
                    { attrs: { mandatory: "" } },
                    [
                      _c(
                        "v-expansion-panel",
                        [
                          _c("v-expansion-panel-header", [
                            _c("div", { staticClass: "header-text" }, [
                              _vm._v("On Deny / On Reschedule / On Cancel"),
                            ]),
                          ]),
                          _c(
                            "v-expansion-panel-content",
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass: "section",
                                  attrs: { dense: "" },
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c(
                                        "v-subheader",
                                        { staticClass: "px-0" },
                                        [
                                          _vm._v(
                                            " Notify the requester when their trip has been denied? "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "3" } },
                                    [
                                      _c(
                                        "v-radio-group",
                                        {
                                          attrs: { row: "" },
                                          model: {
                                            value: _vm.config.requesterOnDeny,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.config,
                                                "requesterOnDeny",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "config.requesterOnDeny",
                                          },
                                        },
                                        [
                                          _c("v-radio", {
                                            attrs: {
                                              label: "Yes",
                                              value: true,
                                            },
                                          }),
                                          _c("v-radio", {
                                            attrs: {
                                              label: "No",
                                              value: false,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                {
                                  staticClass: "section",
                                  attrs: { dense: "" },
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c(
                                        "v-subheader",
                                        { staticClass: "px-0" },
                                        [
                                          _vm._v(
                                            " Notify the following people when a trip is rescheduled? "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "3" } },
                                    [
                                      _c(
                                        "v-radio-group",
                                        {
                                          attrs: { row: "" },
                                          model: {
                                            value: _vm.config.onReschedule,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.config,
                                                "onReschedule",
                                                $$v
                                              )
                                            },
                                            expression: "config.onReschedule",
                                          },
                                        },
                                        [
                                          _c("v-radio", {
                                            attrs: {
                                              label: "Yes",
                                              value: true,
                                            },
                                          }),
                                          _c("v-radio", {
                                            attrs: {
                                              label: "No",
                                              value: false,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "6" } },
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          staticClass: "px-2",
                                          attrs: { dense: "" },
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12", md: "6" } },
                                            [
                                              _c("v-checkbox", {
                                                attrs: {
                                                  "hide-details": "",
                                                  label: "Requester",
                                                },
                                                model: {
                                                  value:
                                                    _vm.config
                                                      .onRescheduleRequester,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.config,
                                                      "onRescheduleRequester",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "config.onRescheduleRequester",
                                                },
                                              }),
                                              _c("v-checkbox", {
                                                attrs: {
                                                  "hide-details": "",
                                                  label: "Approvers",
                                                },
                                                model: {
                                                  value:
                                                    _vm.config
                                                      .onRescheduleApprovers,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.config,
                                                      "onRescheduleApprovers",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "config.onRescheduleApprovers",
                                                },
                                              }),
                                              _c("v-checkbox", {
                                                attrs: {
                                                  "hide-details": "",
                                                  label: "Nurse",
                                                },
                                                model: {
                                                  value:
                                                    _vm.config
                                                      .onRescheduleNurse,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.config,
                                                      "onRescheduleNurse",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "config.onRescheduleNurse",
                                                },
                                              }),
                                              _c("v-checkbox", {
                                                attrs: {
                                                  "hide-details": "",
                                                  label: "Teacher",
                                                },
                                                model: {
                                                  value:
                                                    _vm.config
                                                      .onRescheduleTeacher,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.config,
                                                      "onRescheduleTeacher",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "config.onRescheduleTeacher",
                                                },
                                              }),
                                              _c("v-checkbox", {
                                                attrs: {
                                                  "hide-details": "",
                                                  label: "Contractor",
                                                },
                                                model: {
                                                  value:
                                                    _vm.config
                                                      .onRescheduleContractor,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.config,
                                                      "onRescheduleContractor",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "config.onRescheduleContractor",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12", md: "6" } },
                                            [
                                              _c("v-checkbox", {
                                                attrs: {
                                                  "hide-details": "",
                                                  label: "School Finance",
                                                },
                                                model: {
                                                  value:
                                                    _vm.config
                                                      .onRescheduleSchoolFinance,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.config,
                                                      "onRescheduleSchoolFinance",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "config.onRescheduleSchoolFinance",
                                                },
                                              }),
                                              _c("v-checkbox", {
                                                attrs: {
                                                  "hide-details": "",
                                                  label: "VehicleOwner",
                                                },
                                                model: {
                                                  value:
                                                    _vm.config
                                                      .onRescheduleVehicleOwner,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.config,
                                                      "onRescheduleVehicleOwner",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "config.onRescheduleVehicleOwner",
                                                },
                                              }),
                                              _c("v-checkbox", {
                                                attrs: {
                                                  "hide-details": "",
                                                  label: "Child Nutrition",
                                                },
                                                model: {
                                                  value:
                                                    _vm.config
                                                      .onRescheduleNutritionist,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.config,
                                                      "onRescheduleNutritionist",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "config.onRescheduleNutritionist",
                                                },
                                              }),
                                              _c("v-checkbox", {
                                                attrs: {
                                                  "hide-details": "",
                                                  label: "Email on Destination",
                                                },
                                                model: {
                                                  value:
                                                    _vm.config
                                                      .onRescheduleDestination,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.config,
                                                      "onRescheduleDestination",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "config.onRescheduleDestination",
                                                },
                                              }),
                                              _c("v-checkbox", {
                                                attrs: {
                                                  "hide-details": "",
                                                  label: "Transportation",
                                                },
                                                model: {
                                                  value:
                                                    _vm.config
                                                      .onRescheduleTransportation,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.config,
                                                      "onRescheduleTransportation",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "config.onRescheduleTransportation",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "8" } },
                                    [
                                      _c("user-select", {
                                        attrs: {
                                          value:
                                            _vm.config.onRescheduleAdditional,
                                          users: _vm.users,
                                          label:
                                            "Other people who should receive notifications of rescheduled/canceled trips",
                                          hint: "Add up to 6",
                                          max: 6,
                                        },
                                        on: {
                                          handleUserSelect: function ($event) {
                                            return _vm.handleUserSelect(
                                              $event,
                                              "onRescheduleAdditional"
                                            )
                                          },
                                        },
                                        model: {
                                          value:
                                            _vm.config.onRescheduleAdditional,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.config,
                                              "onRescheduleAdditional",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "config.onRescheduleAdditional",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                {
                                  staticClass: "px-2 section",
                                  attrs: { dense: "" },
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "3" } },
                                    [
                                      _c(
                                        "v-subheader",
                                        { staticClass: "px-0" },
                                        [
                                          _vm._v(
                                            " Notify the following people when an assignment is canceled or rescheduled: "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "checkbox-wrapper" },
                                        _vm._l(
                                          [
                                            {
                                              value: "email",
                                              label: "By Email?",
                                            },
                                            {
                                              value: "text",
                                              label: "By Text?",
                                            },
                                          ],
                                          function (method, i) {
                                            return _c("v-checkbox", {
                                              key: i,
                                              staticClass: "checkbox",
                                              attrs: {
                                                value: method.value,
                                                label: method.label,
                                                multiple: "",
                                              },
                                              model: {
                                                value:
                                                  _vm.config
                                                    .onRescheduleOrCancelAssignment,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.config,
                                                    "onRescheduleOrCancelAssignment",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "config.onRescheduleOrCancelAssignment",
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "6" } },
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          staticClass: "px-2",
                                          attrs: { dense: "" },
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12", md: "6" } },
                                            [
                                              _c("v-checkbox", {
                                                attrs: {
                                                  "hide-details": "",
                                                  label: "Driver",
                                                },
                                                model: {
                                                  value:
                                                    _vm.config
                                                      .onRescheduleOrCancelAssignmentDriver,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.config,
                                                      "onRescheduleOrCancelAssignmentDriver",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "config.onRescheduleOrCancelAssignmentDriver",
                                                },
                                              }),
                                              _c("v-checkbox", {
                                                attrs: {
                                                  "hide-details": "",
                                                  label: "Assistant",
                                                },
                                                model: {
                                                  value:
                                                    _vm.config
                                                      .onRescheduleOrCancelAssignmentAssistant,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.config,
                                                      "onRescheduleOrCancelAssignmentAssistant",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "config.onRescheduleOrCancelAssignmentAssistant",
                                                },
                                              }),
                                              _c("v-checkbox", {
                                                attrs: {
                                                  "hide-details": "",
                                                  label: "Owner",
                                                },
                                                model: {
                                                  value:
                                                    _vm.config
                                                      .onRescheduleOrCancelAssignmentVehicleOwner,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.config,
                                                      "onRescheduleOrCancelAssignmentVehicleOwner",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "config.onRescheduleOrCancelAssignmentVehicleOwner",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                {
                                  staticClass: "section",
                                  attrs: { dense: "" },
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "3" } },
                                    [
                                      _c(
                                        "v-subheader",
                                        { staticClass: "px-0" },
                                        [
                                          _vm._v(
                                            ' Notify destination listed in "Common Travel Destinations" table when trip is canceled? '
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-radio-group",
                                        {
                                          attrs: { row: "" },
                                          model: {
                                            value:
                                              _vm.config.destinationOnCancel,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.config,
                                                "destinationOnCancel",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "config.destinationOnCancel",
                                          },
                                        },
                                        [
                                          _c("v-radio", {
                                            attrs: {
                                              label: "Yes",
                                              value: true,
                                            },
                                          }),
                                          _c("v-radio", {
                                            attrs: {
                                              label: "No",
                                              value: false,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-expansion-panel",
                        [
                          _c("v-expansion-panel-header", [
                            _c("div", { staticClass: "header-text" }, [
                              _vm._v("On Approval"),
                            ]),
                          ]),
                          _c(
                            "v-expansion-panel-content",
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass: "pa-2 section",
                                  attrs: { dense: "" },
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "3" } },
                                    [
                                      _c(
                                        "v-subheader",
                                        { staticClass: "px-0" },
                                        [
                                          _vm._v(
                                            "Notify requester when trip is approved?"
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-radio-group",
                                        {
                                          attrs: { row: "" },
                                          model: {
                                            value:
                                              _vm.config.requesterOnApproval,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.config,
                                                "requesterOnApproval",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "config.requesterOnApproval",
                                          },
                                        },
                                        [
                                          _c("v-radio", {
                                            attrs: {
                                              label: "Yes",
                                              value: true,
                                            },
                                          }),
                                          _c("v-radio", {
                                            attrs: {
                                              label: "No",
                                              value: false,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "5" } },
                                    [
                                      _c("v-subheader", [
                                        _vm._v(
                                          "Additional custom text to append to standard email"
                                        ),
                                      ]),
                                      _c("v-textarea", {
                                        attrs: {
                                          outlined: "",
                                          rows: "1",
                                          "auto-grow": "",
                                        },
                                        model: {
                                          value:
                                            _vm.config
                                              .requesterOnApprovalMessage,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.config,
                                              "requesterOnApprovalMessage",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "config.requesterOnApprovalMessage",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "4" } },
                                    [
                                      _c(
                                        "v-subheader",
                                        { staticClass: "px-0" },
                                        [_vm._v("Trip Types")]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "checkbox-wrapper" },
                                        [
                                          _c("v-checkbox", {
                                            staticClass: "checkbox",
                                            attrs: { label: "All" },
                                            on: {
                                              change: function ($event) {
                                                return _vm.handleAllTypes(
                                                  "requesterOnApprovalTripTypes",
                                                  "selectAllRequesterOnApprovalTripTypes"
                                                )
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.selectAllRequesterOnApprovalTripTypes,
                                              callback: function ($$v) {
                                                _vm.selectAllRequesterOnApprovalTripTypes =
                                                  $$v
                                              },
                                              expression:
                                                "selectAllRequesterOnApprovalTripTypes",
                                            },
                                          }),
                                          _vm._l(
                                            _vm.tripTypes,
                                            function (type, i) {
                                              return _c("v-checkbox", {
                                                key: i,
                                                staticClass: "checkbox",
                                                attrs: {
                                                  value: type.id,
                                                  label: type.name,
                                                  multiple: "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.handleTripTypeSelected(
                                                      "requesterOnApprovalTripTypes",
                                                      "selectAllRequesterOnApprovalTripTypes"
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value:
                                                    _vm.config
                                                      .requesterOnApprovalTripTypes,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.config,
                                                      "requesterOnApprovalTripTypes",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "config.requesterOnApprovalTripTypes",
                                                },
                                              })
                                            }
                                          ),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                {
                                  staticClass: "pa-2 section",
                                  attrs: { dense: "" },
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "3" } },
                                    [
                                      _c(
                                        "v-subheader",
                                        { staticClass: "px-0" },
                                        [
                                          _vm._v(
                                            "Notify site admin(s) when trip is approved?"
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-radio-group",
                                        {
                                          attrs: { row: "" },
                                          model: {
                                            value:
                                              _vm.config.siteAdminOnApproval,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.config,
                                                "siteAdminOnApproval",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "config.siteAdminOnApproval",
                                          },
                                        },
                                        [
                                          _c("v-radio", {
                                            attrs: {
                                              label: "Yes",
                                              value: true,
                                            },
                                          }),
                                          _c("v-radio", {
                                            attrs: {
                                              label: "No",
                                              value: false,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                {
                                  staticClass: "pa-2 section",
                                  attrs: { dense: "" },
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "3" } },
                                    [
                                      _c(
                                        "v-subheader",
                                        { staticClass: "px-0" },
                                        [
                                          _vm._v(
                                            "Notify site nurse(s) when trip is approved?"
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-radio-group",
                                        {
                                          attrs: { row: "" },
                                          model: {
                                            value: _vm.config.nurseOnApproval,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.config,
                                                "nurseOnApproval",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "config.nurseOnApproval",
                                          },
                                        },
                                        [
                                          _c("v-radio", {
                                            attrs: {
                                              label: "Yes",
                                              value: true,
                                            },
                                          }),
                                          _c("v-radio", {
                                            attrs: {
                                              label: "No",
                                              value: false,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "5" } },
                                    [
                                      _c("v-subheader", [
                                        _vm._v("Which trips?"),
                                      ]),
                                      _c(
                                        "div",
                                        { staticClass: "checkbox-wrapper" },
                                        _vm._l(
                                          [
                                            {
                                              label: "All Trips",
                                              value: "all",
                                            },
                                            {
                                              label: "Trips w/ Health Concerns",
                                              value: "healthConcerns",
                                            },
                                          ],
                                          function (type, i) {
                                            return _c("v-checkbox", {
                                              key: i,
                                              staticClass: "checkbox",
                                              attrs: {
                                                value: type.value,
                                                label: type.label,
                                              },
                                              model: {
                                                value:
                                                  _vm.config
                                                    .nurseOnApprovalTrips,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.config,
                                                    "nurseOnApprovalTrips",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "config.nurseOnApprovalTrips",
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "4" } },
                                    [
                                      _c(
                                        "v-subheader",
                                        { staticClass: "px-0" },
                                        [_vm._v("Trip Types")]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "checkbox-wrapper" },
                                        [
                                          _c("v-checkbox", {
                                            staticClass: "checkbox",
                                            attrs: { label: "All" },
                                            on: {
                                              change: function ($event) {
                                                return _vm.handleAllTypes(
                                                  "nurseOnApprovalTripTypes",
                                                  "selectAllNurseOnApprovalTripTypes"
                                                )
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.selectAllNurseOnApprovalTripTypes,
                                              callback: function ($$v) {
                                                _vm.selectAllNurseOnApprovalTripTypes =
                                                  $$v
                                              },
                                              expression:
                                                "selectAllNurseOnApprovalTripTypes",
                                            },
                                          }),
                                          _vm._l(
                                            _vm.tripTypes,
                                            function (type, i) {
                                              return _c("v-checkbox", {
                                                key: i,
                                                staticClass: "checkbox",
                                                attrs: {
                                                  value: type.id,
                                                  label: type.name,
                                                  multiple: "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.handleTripTypeSelected(
                                                      "nurseOnApprovalTripTypes",
                                                      "selectAllNurseOnApprovalTripTypes"
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value:
                                                    _vm.config
                                                      .nurseOnApprovalTripTypes,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.config,
                                                      "nurseOnApprovalTripTypes",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "config.nurseOnApprovalTripTypes",
                                                },
                                              })
                                            }
                                          ),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                {
                                  staticClass: "pa-2 section",
                                  attrs: { dense: "" },
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "3" } },
                                    [
                                      _c(
                                        "v-subheader",
                                        { staticClass: "px-0" },
                                        [
                                          _vm._v(
                                            "Notify nutritionist(s) when trip is approved if students are away during lunch?"
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-radio-group",
                                        {
                                          attrs: { row: "" },
                                          model: {
                                            value:
                                              _vm.config.nutritionistOnApproval,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.config,
                                                "nutritionistOnApproval",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "config.nutritionistOnApproval",
                                          },
                                        },
                                        [
                                          _c("v-radio", {
                                            attrs: {
                                              label: "Yes",
                                              value: true,
                                            },
                                          }),
                                          _c("v-radio", {
                                            attrs: {
                                              label: "No",
                                              value: false,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "5" } },
                                    [
                                      _c(
                                        "v-subheader",
                                        { staticClass: "px-0" },
                                        [
                                          _vm._v(
                                            "Additional custom text to append to standard email"
                                          ),
                                        ]
                                      ),
                                      _c("v-textarea", {
                                        attrs: {
                                          outlined: "",
                                          rows: "1",
                                          "auto-grow": "",
                                        },
                                        model: {
                                          value:
                                            _vm.config
                                              .nutritionistOnApprovalMessage,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.config,
                                              "nutritionistOnApprovalMessage",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "config.nutritionistOnApprovalMessage",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                {
                                  staticClass: "pa-2 section",
                                  attrs: { dense: "" },
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "3" } },
                                    [
                                      _c(
                                        "v-subheader",
                                        { staticClass: "px-0" },
                                        [
                                          _vm._v(
                                            "Notify Finance Department when 3rd party payment is requested?"
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-radio-group",
                                        {
                                          attrs: { row: "" },
                                          model: {
                                            value: _vm.config.financeOnApproval,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.config,
                                                "financeOnApproval",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "config.financeOnApproval",
                                          },
                                        },
                                        [
                                          _c("v-radio", {
                                            attrs: {
                                              label: "Yes",
                                              value: true,
                                            },
                                          }),
                                          _c("v-radio", {
                                            attrs: {
                                              label: "No",
                                              value: false,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "3" } },
                                    [
                                      _c(
                                        "v-subheader",
                                        { staticClass: "px-0" },
                                        [
                                          _vm._v(
                                            "Notify person responsible for payment when 3rd party payment is requested?"
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-radio-group",
                                        {
                                          attrs: { row: "" },
                                          model: {
                                            value:
                                              _vm.config
                                                .schoolFinanceOnApproval,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.config,
                                                "schoolFinanceOnApproval",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "config.schoolFinanceOnApproval",
                                          },
                                        },
                                        [
                                          _c("v-radio", {
                                            attrs: {
                                              label: "Yes",
                                              value: true,
                                            },
                                          }),
                                          _c("v-radio", {
                                            attrs: {
                                              label: "No",
                                              value: false,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                {
                                  staticClass: "pa-2 section",
                                  attrs: { dense: "" },
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "4" } },
                                    [
                                      _c(
                                        "v-subheader",
                                        { staticClass: "px-0" },
                                        [
                                          _vm._v(
                                            "Notify charter companies of trips scheduled to use their buses?"
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-radio-group",
                                        {
                                          attrs: { row: "" },
                                          model: {
                                            value: _vm.config.charterOnApproval,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.config,
                                                "charterOnApproval",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "config.charterOnApproval",
                                          },
                                        },
                                        [
                                          _c("v-radio", {
                                            attrs: {
                                              label: "Yes",
                                              value: true,
                                            },
                                          }),
                                          _c("v-radio", {
                                            attrs: {
                                              label: "No",
                                              value: false,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-subheader",
                                        { staticClass: "px-0" },
                                        [
                                          _vm._v(
                                            "Include link to trip request?"
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-radio-group",
                                        {
                                          attrs: { row: "" },
                                          model: {
                                            value:
                                              _vm.config.charterIncludeLink,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.config,
                                                "charterIncludeLink",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "config.charterIncludeLink",
                                          },
                                        },
                                        [
                                          _c("v-radio", {
                                            attrs: {
                                              label: "Yes",
                                              value: true,
                                            },
                                          }),
                                          _c("v-radio", {
                                            attrs: {
                                              label: "No",
                                              value: false,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "4" } },
                                    [
                                      _c(
                                        "v-subheader",
                                        { staticClass: "px-0" },
                                        [
                                          _vm._v(
                                            "Notify contractors of trips scheduled to use their buses?"
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-radio-group",
                                        {
                                          attrs: { row: "" },
                                          model: {
                                            value:
                                              _vm.config.contractorOnApproval,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.config,
                                                "contractorOnApproval",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "config.contractorOnApproval",
                                          },
                                        },
                                        [
                                          _c("v-radio", {
                                            attrs: {
                                              label: "Yes",
                                              value: true,
                                            },
                                          }),
                                          _c("v-radio", {
                                            attrs: {
                                              label: "No",
                                              value: false,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-subheader",
                                        { staticClass: "px-0" },
                                        [
                                          _vm._v(
                                            "Include link to trip request?"
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-radio-group",
                                        {
                                          attrs: { row: "" },
                                          model: {
                                            value:
                                              _vm.config.contractorIncludeLink,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.config,
                                                "contractorIncludeLink",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "config.contractorIncludeLink",
                                          },
                                        },
                                        [
                                          _c("v-radio", {
                                            attrs: {
                                              label: "Yes",
                                              value: true,
                                            },
                                          }),
                                          _c("v-radio", {
                                            attrs: {
                                              label: "No",
                                              value: false,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "4" } },
                                    [
                                      _c(
                                        "v-subheader",
                                        { staticClass: "px-0" },
                                        [
                                          _vm._v(
                                            "Notify rental/dealership agency of trips scheduled to use their vehicle?"
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-radio-group",
                                        {
                                          attrs: { row: "" },
                                          model: {
                                            value:
                                              _vm.config
                                                .rentalDealershipOnApproval,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.config,
                                                "rentalDealershipOnApproval",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "config.rentalDealershipOnApproval",
                                          },
                                        },
                                        [
                                          _c("v-radio", {
                                            attrs: {
                                              label: "Yes",
                                              value: true,
                                            },
                                          }),
                                          _c("v-radio", {
                                            attrs: {
                                              label: "No",
                                              value: false,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-subheader",
                                        { staticClass: "px-0" },
                                        [
                                          _vm._v(
                                            "Include link to trip request?"
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-radio-group",
                                        {
                                          attrs: { row: "" },
                                          model: {
                                            value:
                                              _vm.config
                                                .rentalDealershipIncludeLink,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.config,
                                                "rentalDealershipIncludeLink",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "config.rentalDealershipIncludeLink",
                                          },
                                        },
                                        [
                                          _c("v-radio", {
                                            attrs: {
                                              label: "Yes",
                                              value: true,
                                            },
                                          }),
                                          _c("v-radio", {
                                            attrs: {
                                              label: "No",
                                              value: false,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                {
                                  staticClass: "pa-2 section",
                                  attrs: { dense: "" },
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "3" } },
                                    [
                                      _c(
                                        "v-subheader",
                                        { staticClass: "px-0" },
                                        [
                                          _vm._v(
                                            "Notify Transportation Admin(s) if trip is auto-approved?"
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-radio-group",
                                        {
                                          attrs: { row: "" },
                                          model: {
                                            value:
                                              _vm.config.adminOnAutoApprove,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.config,
                                                "adminOnAutoApprove",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "config.adminOnAutoApprove",
                                          },
                                        },
                                        [
                                          _c("v-radio", {
                                            attrs: {
                                              label: "Yes",
                                              value: true,
                                            },
                                          }),
                                          _c("v-radio", {
                                            attrs: {
                                              label: "No",
                                              value: false,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                {
                                  staticClass: "pa-2 section",
                                  attrs: { dense: "" },
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "3" } },
                                    [
                                      _c(
                                        "v-subheader",
                                        { staticClass: "px-0" },
                                        [
                                          _vm._v(
                                            "Notify teacher when trip is approved?"
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-radio-group",
                                        {
                                          attrs: { row: "" },
                                          model: {
                                            value: _vm.config.teacherOnApproval,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.config,
                                                "teacherOnApproval",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "config.teacherOnApproval",
                                          },
                                        },
                                        [
                                          _c("v-radio", {
                                            attrs: {
                                              label: "Yes",
                                              value: true,
                                            },
                                          }),
                                          _c("v-radio", {
                                            attrs: {
                                              label: "No",
                                              value: false,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-expansion-panel",
                        [
                          _c("v-expansion-panel-header", [
                            _c("div", { staticClass: "header-text" }, [
                              _vm._v("Assignments"),
                            ]),
                          ]),
                          _c(
                            "v-expansion-panel-content",
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass: "pa-2 section",
                                  attrs: { dense: "" },
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "6" } },
                                    [
                                      _c(
                                        "v-subheader",
                                        { staticClass: "px-0" },
                                        [
                                          _vm._v(
                                            "Notify vehicle owner when assignments can be made on a trip?"
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-radio-group",
                                        {
                                          attrs: { row: "" },
                                          model: {
                                            value:
                                              _vm.config.vehicleOwnerCanAssign,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.config,
                                                "vehicleOwnerCanAssign",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "config.vehicleOwnerCanAssign",
                                          },
                                        },
                                        [
                                          _c("v-radio", {
                                            attrs: {
                                              label: "Yes",
                                              value: true,
                                            },
                                          }),
                                          _c("v-radio", {
                                            attrs: {
                                              label: "No",
                                              value: false,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "6" } },
                                    [
                                      _c(
                                        "v-subheader",
                                        { staticClass: "px-0" },
                                        [
                                          _vm._v(
                                            "Notify vehicle owner when assignments are self-declined?"
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-radio-group",
                                        {
                                          attrs: { row: "" },
                                          model: {
                                            value:
                                              _vm.config
                                                .vehicleOwnerOnSelfDecline,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.config,
                                                "vehicleOwnerOnSelfDecline",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "config.vehicleOwnerOnSelfDecline",
                                          },
                                        },
                                        [
                                          _c("v-radio", {
                                            attrs: {
                                              label: "Yes",
                                              value: true,
                                            },
                                          }),
                                          _c("v-radio", {
                                            attrs: {
                                              label: "No",
                                              value: false,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                {
                                  staticClass: "pa-2 section",
                                  attrs: { dense: "" },
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "3" } },
                                    [
                                      _c(
                                        "v-subheader",
                                        { staticClass: "px-0" },
                                        [
                                          _vm._v(
                                            "Notify requester when assignment is made?"
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-radio-group",
                                        {
                                          attrs: { row: "" },
                                          model: {
                                            value:
                                              _vm.config.requesterOnAssignment,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.config,
                                                "requesterOnAssignment",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "config.requesterOnAssignment",
                                          },
                                        },
                                        [
                                          _c("v-radio", {
                                            attrs: {
                                              label: "Yes",
                                              value: true,
                                            },
                                          }),
                                          _c("v-radio", {
                                            attrs: {
                                              label: "No",
                                              value: false,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "5" } },
                                    [
                                      _c(
                                        "v-subheader",
                                        { staticClass: "px-0" },
                                        [
                                          _vm._v(
                                            "Additional custom text to append to standard email"
                                          ),
                                        ]
                                      ),
                                      _c("v-textarea", {
                                        attrs: {
                                          outlined: "",
                                          rows: "1",
                                          "auto-grow": "",
                                        },
                                        model: {
                                          value:
                                            _vm.config
                                              .requesterOnAssignmentMessage,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.config,
                                              "requesterOnAssignmentMessage",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "config.requesterOnAssignmentMessage",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "4" } },
                                    [
                                      _c("v-subheader", [_vm._v("Trip Types")]),
                                      _c(
                                        "div",
                                        { staticClass: "checkbox-wrapper" },
                                        [
                                          _c("v-checkbox", {
                                            staticClass: "checkbox",
                                            attrs: { label: "All" },
                                            on: {
                                              change: function ($event) {
                                                return _vm.handleAllTypes(
                                                  "requesterOnAssignmentTripTypes",
                                                  "selectAllRequesterOnAssignmentTripTypes"
                                                )
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.selectAllRequesterOnAssignmentTripTypes,
                                              callback: function ($$v) {
                                                _vm.selectAllRequesterOnAssignmentTripTypes =
                                                  $$v
                                              },
                                              expression:
                                                "selectAllRequesterOnAssignmentTripTypes",
                                            },
                                          }),
                                          _vm._l(
                                            _vm.tripTypes,
                                            function (type, i) {
                                              return _c("v-checkbox", {
                                                key: i,
                                                staticClass: "checkbox",
                                                attrs: {
                                                  value: type.id,
                                                  label: type.name,
                                                  multiple: "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.handleTripTypeSelected(
                                                      "requesterOnAssignmentTripTypes",
                                                      "selectAllRequesterOnAssignmentTripTypes"
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value:
                                                    _vm.config
                                                      .requesterOnAssignmentTripTypes,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.config,
                                                      "requesterOnAssignmentTripTypes",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "config.requesterOnAssignmentTripTypes",
                                                },
                                              })
                                            }
                                          ),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                {
                                  staticClass: "pa-2 section",
                                  attrs: { dense: "" },
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "3" } },
                                    [
                                      _c(
                                        "v-subheader",
                                        { staticClass: "px-0" },
                                        [
                                          _vm._v(
                                            " Notify vehicle drivers (and assistants if used) when vehicle/driver assignment has been made? "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "checkbox-wrapper" },
                                        _vm._l(
                                          [
                                            {
                                              value: "email",
                                              label: "By Email?",
                                            },
                                            {
                                              value: "text",
                                              label: "By Text?",
                                            },
                                          ],
                                          function (method, i) {
                                            return _c("v-checkbox", {
                                              key: i,
                                              staticClass: "checkbox",
                                              attrs: {
                                                value: method.value,
                                                label: method.label,
                                                multiple: "",
                                              },
                                              model: {
                                                value:
                                                  _vm.config.driverOnAssignment,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.config,
                                                    "driverOnAssignment",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "config.driverOnAssignment",
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "4" } },
                                    [
                                      _c(
                                        "v-subheader",
                                        { staticClass: "px-0" },
                                        [
                                          _vm._v(
                                            "Additional custom text to append to driver email"
                                          ),
                                        ]
                                      ),
                                      _c("v-textarea", {
                                        attrs: {
                                          outlined: "",
                                          rows: "1",
                                          "auto-grow": "",
                                        },
                                        model: {
                                          value:
                                            _vm.config
                                              .driverOnAssignmentMessage,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.config,
                                              "driverOnAssignmentMessage",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "config.driverOnAssignmentMessage",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "4" } },
                                    [
                                      _c(
                                        "v-subheader",
                                        { staticClass: "px-0" },
                                        [
                                          _vm._v(
                                            "Additional custom text to append to assistant email"
                                          ),
                                        ]
                                      ),
                                      _c("v-textarea", {
                                        attrs: {
                                          outlined: "",
                                          rows: "1",
                                          "auto-grow": "",
                                        },
                                        model: {
                                          value:
                                            _vm.config
                                              .assistantOnAssignmentMessage,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.config,
                                              "assistantOnAssignmentMessage",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "config.assistantOnAssignmentMessage",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.me.is.superAdmin,
                                          expression: "me.is.superAdmin",
                                        },
                                      ],
                                      attrs: { cols: "12", md: "3" },
                                    },
                                    [
                                      _c(
                                        "v-subheader",
                                        { staticClass: "px-0" },
                                        [
                                          _vm._v(
                                            " What time should driver/assistant notifications be sent out? (in seconds) "
                                          ),
                                        ]
                                      ),
                                      _c("v-text-field", {
                                        attrs: {
                                          type: "number",
                                          min: "0",
                                          hint: "66900 = 6:35pm",
                                          "persistent-hint": "",
                                        },
                                        model: {
                                          value:
                                            _vm.config.driverOnAssignmentTime,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.config,
                                              "driverOnAssignmentTime",
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                            )
                                          },
                                          expression:
                                            "config.driverOnAssignmentTime",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              false
                                ? _c(
                                    "v-row",
                                    {
                                      staticClass: "pa-2 section",
                                      attrs: { dense: "" },
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12", md: "4" } },
                                        [
                                          _c(
                                            "v-subheader",
                                            { staticClass: "pl-0" },
                                            [
                                              _vm._v(
                                                " Exclude link to access assignments in driver emails? "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "v-radio-group",
                                            {
                                              attrs: { row: "" },
                                              model: {
                                                value: _vm.config.excludeLinks,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.config,
                                                    "excludeLinks",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "config.excludeLinks",
                                              },
                                            },
                                            [
                                              _c("v-radio", {
                                                attrs: { value: true },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "label",
                                                      fn: function () {
                                                        return [_vm._v(" Yes ")]
                                                      },
                                                      proxy: true,
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  2895847252
                                                ),
                                              }),
                                              _c("v-radio", {
                                                attrs: { value: false },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "label",
                                                      fn: function () {
                                                        return [_vm._v(" No ")]
                                                      },
                                                      proxy: true,
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  3342871450
                                                ),
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "v-row",
                                {
                                  staticClass: "pa-2 section",
                                  attrs: { dense: "" },
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "4" } },
                                    [
                                      _c(
                                        "v-subheader",
                                        { staticClass: "pl-0" },
                                        [
                                          _vm._v(
                                            " Include links for Drivers to accept or decline assignment? "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-radio-group",
                                        {
                                          attrs: {
                                            row: "",
                                            disabled:
                                              !_vm.driverConfig
                                                .useAvailableDrivers,
                                          },
                                          model: {
                                            value:
                                              _vm.config
                                                .includeAcceptDeclineLink,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.config,
                                                "includeAcceptDeclineLink",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "config.includeAcceptDeclineLink",
                                          },
                                        },
                                        [
                                          _c("v-radio", {
                                            attrs: { value: true },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "label",
                                                fn: function () {
                                                  return [_vm._v(" Yes ")]
                                                },
                                                proxy: true,
                                              },
                                            ]),
                                          }),
                                          _c("v-radio", {
                                            attrs: { value: false },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "label",
                                                fn: function () {
                                                  return [_vm._v(" No ")]
                                                },
                                                proxy: true,
                                              },
                                            ]),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                {
                                  staticClass: "pa-2 section",
                                  attrs: { dense: "" },
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "6" } },
                                    [
                                      _c(
                                        "v-subheader",
                                        { staticClass: "px-0" },
                                        [
                                          _vm._v(
                                            "Notify teacher when assignment is made?"
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-radio-group",
                                        {
                                          attrs: { row: "" },
                                          model: {
                                            value:
                                              _vm.config
                                                .teacherOnAssignmentMade,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.config,
                                                "teacherOnAssignmentMade",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "config.teacherOnAssignmentMade",
                                          },
                                        },
                                        [
                                          _c("v-radio", {
                                            attrs: {
                                              label: "Yes",
                                              value: true,
                                            },
                                          }),
                                          _c("v-radio", {
                                            attrs: {
                                              label: "No",
                                              value: false,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-expansion-panel",
                        [
                          _c("v-expansion-panel-header", [
                            _c("div", { staticClass: "header-text" }, [
                              _vm._v("Scheduled"),
                            ]),
                          ]),
                          _c(
                            "v-expansion-panel-content",
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass: "pa-2 section",
                                  attrs: { dense: "" },
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "3" } },
                                    [
                                      _c(
                                        "v-subheader",
                                        { staticClass: "px-0" },
                                        [
                                          _vm._v(
                                            "Notify Requestor Day Before Trip"
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-radio-group",
                                        {
                                          attrs: { row: "" },
                                          model: {
                                            value:
                                              _vm.config.requesterDayBefore,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.config,
                                                "requesterDayBefore",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "config.requesterDayBefore",
                                          },
                                        },
                                        [
                                          _c("v-radio", {
                                            attrs: {
                                              label: "Yes",
                                              value: true,
                                            },
                                          }),
                                          _c("v-radio", {
                                            attrs: {
                                              label: "No",
                                              value: false,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "5" } },
                                    [
                                      _c(
                                        "v-subheader",
                                        { staticClass: "px-0" },
                                        [
                                          _vm._v(
                                            "Additional custom text to append to standard email"
                                          ),
                                        ]
                                      ),
                                      _c("v-textarea", {
                                        attrs: {
                                          outlined: "",
                                          rows: "1",
                                          "auto-grow": "",
                                        },
                                        model: {
                                          value:
                                            _vm.config
                                              .requesterDayBeforeMessage,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.config,
                                              "requesterDayBeforeMessage",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "config.requesterDayBeforeMessage",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "4" } },
                                    [
                                      _c("v-subheader", [_vm._v("Trip Types")]),
                                      _c(
                                        "div",
                                        { staticClass: "checkbox-wrapper" },
                                        [
                                          _c("v-checkbox", {
                                            staticClass: "checkbox",
                                            attrs: { label: "All" },
                                            on: {
                                              change: function ($event) {
                                                return _vm.handleAllTypes(
                                                  "requesterDayBeforeTripTypes",
                                                  "selectAllRequesterDayBeforeTripTypes"
                                                )
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.selectAllRequesterDayBeforeTripTypes,
                                              callback: function ($$v) {
                                                _vm.selectAllRequesterDayBeforeTripTypes =
                                                  $$v
                                              },
                                              expression:
                                                "selectAllRequesterDayBeforeTripTypes",
                                            },
                                          }),
                                          _vm._l(
                                            _vm.tripTypes,
                                            function (type, i) {
                                              return _c("v-checkbox", {
                                                key: i,
                                                staticClass: "checkbox",
                                                attrs: {
                                                  value: type.id,
                                                  label: type.name,
                                                  multiple: "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.handleTripTypeSelected(
                                                      "requesterDayBeforeTripTypes",
                                                      "selectAllRequesterDayBeforeTripTypes"
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value:
                                                    _vm.config
                                                      .requesterDayBeforeTripTypes,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.config,
                                                      "requesterDayBeforeTripTypes",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "config.requesterDayBeforeTripTypes",
                                                },
                                              })
                                            }
                                          ),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                {
                                  staticClass: "pa-2 section",
                                  attrs: { dense: "" },
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "3" } },
                                    [
                                      _c(
                                        "v-subheader",
                                        { staticClass: "px-0" },
                                        [
                                          _vm._v(
                                            "Notify Requestor Day After Trip"
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-radio-group",
                                        {
                                          attrs: { row: "" },
                                          model: {
                                            value: _vm.config.requesterDayAfter,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.config,
                                                "requesterDayAfter",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "config.requesterDayAfter",
                                          },
                                        },
                                        [
                                          _c("v-radio", {
                                            attrs: {
                                              label: "Yes",
                                              value: true,
                                            },
                                          }),
                                          _c("v-radio", {
                                            attrs: {
                                              label: "No",
                                              value: false,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "5" } },
                                    [
                                      _c(
                                        "v-subheader",
                                        { staticClass: "px-0" },
                                        [
                                          _vm._v(
                                            "Additional custom text to append to standard email"
                                          ),
                                        ]
                                      ),
                                      _c("v-textarea", {
                                        attrs: {
                                          outlined: "",
                                          rows: "1",
                                          "auto-grow": "",
                                        },
                                        model: {
                                          value:
                                            _vm.config.requesterDayAfterMessage,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.config,
                                              "requesterDayAfterMessage",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "config.requesterDayAfterMessage",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "4" } },
                                    [
                                      _c(
                                        "v-subheader",
                                        { staticClass: "px-0" },
                                        [_vm._v("Trip Types")]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "checkbox-wrapper" },
                                        [
                                          _c("v-checkbox", {
                                            staticClass: "checkbox",
                                            attrs: { label: "All" },
                                            on: {
                                              change: function ($event) {
                                                return _vm.handleAllTypes(
                                                  "requesterDayAfterTripTypes",
                                                  "selectAllRequesterDayAfterTripTypes"
                                                )
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.selectAllRequesterDayAfterTripTypes,
                                              callback: function ($$v) {
                                                _vm.selectAllRequesterDayAfterTripTypes =
                                                  $$v
                                              },
                                              expression:
                                                "selectAllRequesterDayAfterTripTypes",
                                            },
                                          }),
                                          _vm._l(
                                            _vm.tripTypes,
                                            function (type, i) {
                                              return _c("v-checkbox", {
                                                key: i,
                                                staticClass: "checkbox",
                                                attrs: {
                                                  value: type.id,
                                                  label: type.name,
                                                  multiple: "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.handleTripTypeSelected(
                                                      "requesterDayAfterTripTypes",
                                                      "selectAllRequesterDayAfterTripTypes"
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value:
                                                    _vm.config
                                                      .requesterDayAfterTripTypes,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.config,
                                                      "requesterDayAfterTripTypes",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "config.requesterDayAfterTripTypes",
                                                },
                                              })
                                            }
                                          ),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                {
                                  staticClass: "pa-2 section",
                                  attrs: { dense: "" },
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "3" } },
                                    [
                                      _c(
                                        "v-subheader",
                                        { staticClass: "px-0" },
                                        [
                                          _vm._v(
                                            " Notify driver by email day before trip? "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "checkbox-wrapper" },
                                        _vm._l(
                                          [
                                            {
                                              value: "email",
                                              label: "By Email?",
                                            },
                                            {
                                              value: "text",
                                              label: "By Text?",
                                            },
                                          ],
                                          function (method, i) {
                                            return _c("v-checkbox", {
                                              key: i,
                                              staticClass: "checkbox",
                                              attrs: {
                                                value: method.value,
                                                label: method.label,
                                                multiple: "",
                                              },
                                              model: {
                                                value:
                                                  _vm.config.driverDayBefore,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.config,
                                                    "driverDayBefore",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "config.driverDayBefore",
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "5" } },
                                    [
                                      _c(
                                        "v-subheader",
                                        { staticClass: "px-0" },
                                        [
                                          _vm._v(
                                            "Additional custom text to append to standard email"
                                          ),
                                        ]
                                      ),
                                      _c("v-textarea", {
                                        attrs: {
                                          outlined: "",
                                          rows: "1",
                                          "auto-grow": "",
                                        },
                                        model: {
                                          value:
                                            _vm.config.driverDayBeforeMessage,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.config,
                                              "driverDayBeforeMessage",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "config.driverDayBeforeMessage",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                {
                                  staticClass: "pa-2 section",
                                  attrs: { dense: "" },
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "3" } },
                                    [
                                      _c(
                                        "v-subheader",
                                        { staticClass: "px-0" },
                                        [
                                          _vm._v(
                                            "Notify Child Nutrition of students away during lunch 4 days before trip?"
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-radio-group",
                                        {
                                          attrs: { row: "" },
                                          model: {
                                            value:
                                              _vm.config
                                                .nutritionistFourDaysBefore,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.config,
                                                "nutritionistFourDaysBefore",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "config.nutritionistFourDaysBefore",
                                          },
                                        },
                                        [
                                          _c("v-radio", {
                                            attrs: {
                                              label: "Yes",
                                              value: true,
                                            },
                                          }),
                                          _c("v-radio", {
                                            attrs: {
                                              label: "No",
                                              value: false,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "5" } },
                                    [
                                      _c(
                                        "v-subheader",
                                        { staticClass: "px-0" },
                                        [
                                          _vm._v(
                                            "Additional custom text to append to standard email"
                                          ),
                                        ]
                                      ),
                                      _c("v-textarea", {
                                        attrs: {
                                          outlined: "",
                                          rows: "1",
                                          "auto-grow": "",
                                        },
                                        model: {
                                          value:
                                            _vm.config
                                              .nutritionistFourDaysBeforeMessage,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.config,
                                              "nutritionistFourDaysBeforeMessage",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "config.nutritionistFourDaysBeforeMessage",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                {
                                  staticClass: "pa-2 section",
                                  attrs: { dense: "" },
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "3" } },
                                    [
                                      _c(
                                        "v-subheader",
                                        { staticClass: "px-0" },
                                        [
                                          _vm._v(
                                            "Notify charter companies 7 days before trip?"
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-radio-group",
                                        {
                                          attrs: { row: "" },
                                          model: {
                                            value:
                                              _vm.config.charterSevenDaysBefore,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.config,
                                                "charterSevenDaysBefore",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "config.charterSevenDaysBefore",
                                          },
                                        },
                                        [
                                          _c("v-radio", {
                                            attrs: {
                                              label: "Yes",
                                              value: true,
                                            },
                                          }),
                                          _c("v-radio", {
                                            attrs: {
                                              label: "No",
                                              value: false,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "3" } },
                                    [
                                      _c(
                                        "v-subheader",
                                        { staticClass: "px-0" },
                                        [
                                          _vm._v(
                                            "Notify contractor 7 days before trip?"
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-radio-group",
                                        {
                                          attrs: { row: "" },
                                          model: {
                                            value:
                                              _vm.config
                                                .contractorSevenDaysBefore,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.config,
                                                "contractorSevenDaysBefore",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "config.contractorSevenDaysBefore",
                                          },
                                        },
                                        [
                                          _c("v-radio", {
                                            attrs: {
                                              label: "Yes",
                                              value: true,
                                            },
                                          }),
                                          _c("v-radio", {
                                            attrs: {
                                              label: "No",
                                              value: false,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              false
                                ? _c(
                                    "v-row",
                                    {
                                      staticClass: "pa-2 section",
                                      attrs: { dense: "" },
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12", md: "3" } },
                                        [
                                          _c(
                                            "v-subheader",
                                            { staticClass: "px-0" },
                                            [
                                              _vm._v(
                                                "Send weekly email to drivers listing their upcoming assignments for next two weeks?"
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "checkbox-wrapper" },
                                            _vm._l(
                                              [
                                                {
                                                  value: "email",
                                                  label: "By Email?",
                                                },
                                                {
                                                  value: "text",
                                                  label: "By Text?",
                                                },
                                              ],
                                              function (method, i) {
                                                return _c("v-checkbox", {
                                                  key: i,
                                                  staticClass: "checkbox",
                                                  attrs: {
                                                    value: method.value,
                                                    label: method.label,
                                                    multiple: "",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.config.driverWeekly,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.config,
                                                        "driverWeekly",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "config.driverWeekly",
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-expansion-panel",
                        [
                          _c("v-expansion-panel-header", [
                            _c("div", { staticClass: "header-text" }, [
                              _vm._v("Mechanics"),
                            ]),
                          ]),
                          _c(
                            "v-expansion-panel-content",
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass: "pa-2 section",
                                  attrs: { dense: "" },
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "3" } },
                                    [
                                      _c(
                                        "v-subheader",
                                        { staticClass: "px-0" },
                                        [
                                          _vm._v(
                                            " Notify mechanic by email 3 days before trip? "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-radio-group",
                                        {
                                          attrs: { row: "" },
                                          model: {
                                            value:
                                              _vm.config
                                                .mechanicThreeDaysBefore,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.config,
                                                "mechanicThreeDaysBefore",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "config.mechanicThreeDaysBefore",
                                          },
                                        },
                                        [
                                          _c("v-radio", {
                                            attrs: {
                                              label: "Yes",
                                              value: true,
                                            },
                                          }),
                                          _c("v-radio", {
                                            attrs: {
                                              label: "No",
                                              value: false,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                {
                                  staticClass: "pa-2 section",
                                  attrs: { dense: "" },
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "8" } },
                                    [
                                      _c("user-select", {
                                        attrs: {
                                          value:
                                            _vm.config.notifyOnVehicleComments,
                                          users: _vm.users,
                                          multiple: false,
                                          label:
                                            "Email of person to be notified about comments or problems concerning a vehicle",
                                        },
                                        on: {
                                          handleUserSelect: function ($event) {
                                            return _vm.handleUserSelect(
                                              $event,
                                              "notifyOnVehicleComments"
                                            )
                                          },
                                        },
                                        model: {
                                          value:
                                            _vm.config.notifyOnVehicleComments,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.config,
                                              "notifyOnVehicleComments",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "config.notifyOnVehicleComments",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-expansion-panel",
                        [
                          _c("v-expansion-panel-header", [
                            _c("div", { staticClass: "header-text" }, [
                              _vm._v("Invoice"),
                            ]),
                          ]),
                          _c(
                            "v-expansion-panel-content",
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass: "pa-2 section",
                                  attrs: { dense: "" },
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "6" } },
                                    [
                                      _c(
                                        "v-subheader",
                                        { staticClass: "px-0" },
                                        [
                                          _vm._v(
                                            'Send weekly emails to staff responsible for payment who have not confirmed "Payment Sent" on completed trips.'
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-radio-group",
                                        {
                                          attrs: { row: "" },
                                          model: {
                                            value:
                                              _vm.config
                                                .weeklyEmailSchoolPersonPaymentReminder,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.config,
                                                "weeklyEmailSchoolPersonPaymentReminder",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "config.weeklyEmailSchoolPersonPaymentReminder",
                                          },
                                        },
                                        [
                                          _c("v-radio", {
                                            attrs: {
                                              label: "Yes",
                                              value: true,
                                            },
                                          }),
                                          _c("v-radio", {
                                            attrs: {
                                              label: "No",
                                              value: false,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                {
                                  staticClass: "pa-2 section",
                                  attrs: { dense: "" },
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "6" } },
                                    [
                                      _c(
                                        "v-subheader",
                                        { staticClass: "px-0" },
                                        [
                                          _vm._v(
                                            'Send weekly emails to funding payment or funding approver staff who have not confirmed "Payment Sent" on completed trips.'
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-radio-group",
                                        {
                                          attrs: { row: "" },
                                          model: {
                                            value:
                                              _vm.config
                                                .weeklyEmailFundingPersonPaymentReminder,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.config,
                                                "weeklyEmailFundingPersonPaymentReminder",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "config.weeklyEmailFundingPersonPaymentReminder",
                                          },
                                        },
                                        [
                                          _c("v-radio", {
                                            attrs: {
                                              label: "Yes",
                                              value: true,
                                            },
                                          }),
                                          _c("v-radio", {
                                            attrs: {
                                              label: "No",
                                              value: false,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }