<template>
  <v-container fluid>
    <v-form :readonly="!me.is.superAdmin && !me.is.transportationAdmin">
      <v-card>
        <v-card-title>Notifications</v-card-title>
        <v-card-text>
          <v-expansion-panels mandatory>
            <v-expansion-panel>
              <v-expansion-panel-header>
                <div class="header-text">On Deny / On Reschedule / On Cancel</div></v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <v-row class="section" dense>
                  <v-col cols="12">
                    <v-subheader class="px-0"> Notify the requester when their trip has been denied? </v-subheader>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-radio-group v-model="config.requesterOnDeny" row>
                      <v-radio label="Yes" :value="true"></v-radio>
                      <v-radio label="No" :value="false"></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
                <v-row class="section" dense>
                  <v-col cols="12">
                    <v-subheader class="px-0"> Notify the following people when a trip is rescheduled? </v-subheader>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-radio-group v-model="config.onReschedule" row>
                      <v-radio label="Yes" :value="true"></v-radio>
                      <v-radio label="No" :value="false"></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-row class="px-2" dense>
                      <v-col cols="12" md="6">
                        <v-checkbox hide-details v-model="config.onRescheduleRequester" label="Requester"></v-checkbox>
                        <v-checkbox hide-details v-model="config.onRescheduleApprovers" label="Approvers"></v-checkbox>
                        <v-checkbox hide-details v-model="config.onRescheduleNurse" label="Nurse"></v-checkbox>
                        <v-checkbox hide-details v-model="config.onRescheduleTeacher" label="Teacher"></v-checkbox>
                        <v-checkbox
                          hide-details
                          v-model="config.onRescheduleContractor"
                          label="Contractor"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-checkbox
                          hide-details
                          v-model="config.onRescheduleSchoolFinance"
                          label="School Finance"
                        ></v-checkbox>
                        <v-checkbox
                          hide-details
                          v-model="config.onRescheduleVehicleOwner"
                          label="VehicleOwner"
                        ></v-checkbox>
                        <v-checkbox
                          hide-details
                          v-model="config.onRescheduleNutritionist"
                          label="Child Nutrition"
                        ></v-checkbox>
                        <v-checkbox
                          hide-details
                          v-model="config.onRescheduleDestination"
                          label="Email on Destination"
                        ></v-checkbox>
                        <v-checkbox
                          hide-details
                          v-model="config.onRescheduleTransportation"
                          label="Transportation"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" md="8">
                    <user-select
                      :value="config.onRescheduleAdditional"
                      v-model="config.onRescheduleAdditional"
                      :users="users"
                      label="Other people who should receive notifications of rescheduled/canceled trips"
                      hint="Add up to 6"
                      :max="6"
                      @handleUserSelect="handleUserSelect($event, 'onRescheduleAdditional')"
                    >
                    </user-select>
                  </v-col>
                </v-row>
                <v-row class="px-2 section" dense>
                  <v-col cols="12" md="3">
                    <v-subheader class="px-0">
                      Notify the following people when an assignment is canceled or rescheduled:
                    </v-subheader>
                    <div class="checkbox-wrapper">
                      <v-checkbox
                        v-for="(method, i) in [
                          { value: 'email', label: 'By Email?' },
                          { value: 'text', label: 'By Text?' },
                        ]"
                        :key="i"
                        v-model="config.onRescheduleOrCancelAssignment"
                        :value="method.value"
                        :label="method.label"
                        class="checkbox"
                        multiple
                      ></v-checkbox>
                    </div>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-row class="px-2" dense>
                      <v-col cols="12" md="6">
                        <v-checkbox
                          hide-details
                          v-model="config.onRescheduleOrCancelAssignmentDriver"
                          label="Driver"
                        ></v-checkbox>
                        <v-checkbox
                          hide-details
                          v-model="config.onRescheduleOrCancelAssignmentAssistant"
                          label="Assistant"
                        ></v-checkbox>
                        <v-checkbox
                          hide-details
                          v-model="config.onRescheduleOrCancelAssignmentVehicleOwner"
                          label="Owner"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row class="section" dense>
                  <v-col cols="12" md="3">
                    <v-subheader class="px-0">
                      Notify destination listed in "Common Travel Destinations" table when trip is canceled?
                    </v-subheader>
                    <v-radio-group v-model="config.destinationOnCancel" row>
                      <v-radio label="Yes" :value="true"></v-radio>
                      <v-radio label="No" :value="false"></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header>
                <div class="header-text">On Approval</div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row class="pa-2 section" dense>
                  <v-col cols="12" md="3">
                    <v-subheader class="px-0">Notify requester when trip is approved?</v-subheader>
                    <v-radio-group v-model="config.requesterOnApproval" row>
                      <v-radio label="Yes" :value="true"></v-radio>
                      <v-radio label="No" :value="false"></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="12" md="5">
                    <v-subheader>Additional custom text to append to standard email</v-subheader>
                    <v-textarea v-model="config.requesterOnApprovalMessage" outlined rows="1" auto-grow></v-textarea>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-subheader class="px-0">Trip Types</v-subheader>
                    <div class="checkbox-wrapper">
                      <v-checkbox
                        v-model="selectAllRequesterOnApprovalTripTypes"
                        class="checkbox"
                        label="All"
                        @change="
                          handleAllTypes('requesterOnApprovalTripTypes', 'selectAllRequesterOnApprovalTripTypes')
                        "
                      ></v-checkbox>
                      <v-checkbox
                        v-for="(type, i) in tripTypes"
                        :key="i"
                        class="checkbox"
                        :value="type.id"
                        v-model="config.requesterOnApprovalTripTypes"
                        :label="type.name"
                        multiple
                        @click="
                          handleTripTypeSelected(
                            'requesterOnApprovalTripTypes',
                            'selectAllRequesterOnApprovalTripTypes'
                          )
                        "
                      ></v-checkbox>
                    </div>
                  </v-col>
                </v-row>
                <v-row class="pa-2 section" dense>
                  <v-col cols="12" md="3">
                    <v-subheader class="px-0">Notify site admin(s) when trip is approved?</v-subheader>
                    <v-radio-group v-model="config.siteAdminOnApproval" row>
                      <v-radio label="Yes" :value="true"></v-radio>
                      <v-radio label="No" :value="false"></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
                <v-row class="pa-2 section" dense>
                  <v-col cols="12" md="3">
                    <v-subheader class="px-0">Notify site nurse(s) when trip is approved?</v-subheader>
                    <v-radio-group v-model="config.nurseOnApproval" row>
                      <v-radio label="Yes" :value="true"></v-radio>
                      <v-radio label="No" :value="false"></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="12" md="5">
                    <v-subheader>Which trips?</v-subheader>
                    <div class="checkbox-wrapper">
                      <v-checkbox
                        v-for="(type, i) in [
                          { label: 'All Trips', value: 'all' },
                          { label: 'Trips w/ Health Concerns', value: 'healthConcerns' },
                        ]"
                        :key="i"
                        v-model="config.nurseOnApprovalTrips"
                        class="checkbox"
                        :value="type.value"
                        :label="type.label"
                      ></v-checkbox>
                    </div>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-subheader class="px-0">Trip Types</v-subheader>
                    <div class="checkbox-wrapper">
                      <v-checkbox
                        v-model="selectAllNurseOnApprovalTripTypes"
                        class="checkbox"
                        label="All"
                        @change="handleAllTypes('nurseOnApprovalTripTypes', 'selectAllNurseOnApprovalTripTypes')"
                      ></v-checkbox>
                      <v-checkbox
                        v-for="(type, i) in tripTypes"
                        :key="i"
                        class="checkbox"
                        :value="type.id"
                        v-model="config.nurseOnApprovalTripTypes"
                        :label="type.name"
                        multiple
                        @click="handleTripTypeSelected('nurseOnApprovalTripTypes', 'selectAllNurseOnApprovalTripTypes')"
                      ></v-checkbox>
                    </div>
                  </v-col>
                </v-row>
                <v-row class="pa-2 section" dense>
                  <v-col cols="12" md="3">
                    <v-subheader class="px-0"
                      >Notify nutritionist(s) when trip is approved if students are away during lunch?</v-subheader
                    >
                    <v-radio-group v-model="config.nutritionistOnApproval" row>
                      <v-radio label="Yes" :value="true"></v-radio>
                      <v-radio label="No" :value="false"></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="12" md="5">
                    <v-subheader class="px-0">Additional custom text to append to standard email</v-subheader>
                    <v-textarea v-model="config.nutritionistOnApprovalMessage" outlined rows="1" auto-grow></v-textarea>
                  </v-col>
                </v-row>
                <v-row class="pa-2 section" dense>
                  <v-col cols="12" md="3">
                    <v-subheader class="px-0"
                      >Notify Finance Department when 3rd party payment is requested?</v-subheader
                    >
                    <v-radio-group v-model="config.financeOnApproval" row>
                      <v-radio label="Yes" :value="true"></v-radio>
                      <v-radio label="No" :value="false"></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-subheader class="px-0"
                      >Notify person responsible for payment when 3rd party payment is requested?</v-subheader
                    >
                    <v-radio-group v-model="config.schoolFinanceOnApproval" row>
                      <v-radio label="Yes" :value="true"></v-radio>
                      <v-radio label="No" :value="false"></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
                <v-row class="pa-2 section" dense>
                  <v-col cols="12" md="4">
                    <v-subheader class="px-0"
                      >Notify charter companies of trips scheduled to use their buses?</v-subheader
                    >
                    <v-radio-group v-model="config.charterOnApproval" row>
                      <v-radio label="Yes" :value="true"></v-radio>
                      <v-radio label="No" :value="false"></v-radio>
                    </v-radio-group>
                    <v-subheader class="px-0">Include link to trip request?</v-subheader>
                    <v-radio-group v-model="config.charterIncludeLink" row>
                      <v-radio label="Yes" :value="true"></v-radio>
                      <v-radio label="No" :value="false"></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-subheader class="px-0">Notify contractors of trips scheduled to use their buses?</v-subheader>
                    <v-radio-group v-model="config.contractorOnApproval" row>
                      <v-radio label="Yes" :value="true"></v-radio>
                      <v-radio label="No" :value="false"></v-radio>
                    </v-radio-group>
                    <v-subheader class="px-0">Include link to trip request?</v-subheader>
                    <v-radio-group v-model="config.contractorIncludeLink" row>
                      <v-radio label="Yes" :value="true"></v-radio>
                      <v-radio label="No" :value="false"></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-subheader class="px-0"
                      >Notify rental/dealership agency of trips scheduled to use their vehicle?</v-subheader
                    >
                    <v-radio-group v-model="config.rentalDealershipOnApproval" row>
                      <v-radio label="Yes" :value="true"></v-radio>
                      <v-radio label="No" :value="false"></v-radio>
                    </v-radio-group>
                    <v-subheader class="px-0">Include link to trip request?</v-subheader>
                    <v-radio-group v-model="config.rentalDealershipIncludeLink" row>
                      <v-radio label="Yes" :value="true"></v-radio>
                      <v-radio label="No" :value="false"></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
                <v-row class="pa-2 section" dense>
                  <v-col cols="12" md="3">
                    <v-subheader class="px-0">Notify Transportation Admin(s) if trip is auto-approved?</v-subheader>
                    <v-radio-group v-model="config.adminOnAutoApprove" row>
                      <v-radio label="Yes" :value="true"></v-radio>
                      <v-radio label="No" :value="false"></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
                <v-row class="pa-2 section" dense>
                  <v-col cols="12" md="3">
                    <v-subheader class="px-0">Notify teacher when trip is approved?</v-subheader>
                    <v-radio-group v-model="config.teacherOnApproval" row>
                      <v-radio label="Yes" :value="true"></v-radio>
                      <v-radio label="No" :value="false"></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header>
                <div class="header-text">Assignments</div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row class="pa-2 section" dense>
                  <v-col cols="12" md="6">
                    <v-subheader class="px-0">Notify vehicle owner when assignments can be made on a trip?</v-subheader>
                    <v-radio-group v-model="config.vehicleOwnerCanAssign" row>
                      <v-radio label="Yes" :value="true"></v-radio>
                      <v-radio label="No" :value="false"></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-subheader class="px-0">Notify vehicle owner when assignments are self-declined?</v-subheader>
                    <v-radio-group v-model="config.vehicleOwnerOnSelfDecline" row>
                      <v-radio label="Yes" :value="true"></v-radio>
                      <v-radio label="No" :value="false"></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
                <v-row class="pa-2 section" dense>
                  <v-col cols="12" md="3">
                    <v-subheader class="px-0">Notify requester when assignment is made?</v-subheader>
                    <v-radio-group v-model="config.requesterOnAssignment" row>
                      <v-radio label="Yes" :value="true"></v-radio>
                      <v-radio label="No" :value="false"></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="12" md="5">
                    <v-subheader class="px-0">Additional custom text to append to standard email</v-subheader>
                    <v-textarea v-model="config.requesterOnAssignmentMessage" outlined rows="1" auto-grow></v-textarea>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-subheader>Trip Types</v-subheader>
                    <div class="checkbox-wrapper">
                      <v-checkbox
                        v-model="selectAllRequesterOnAssignmentTripTypes"
                        class="checkbox"
                        label="All"
                        @change="
                          handleAllTypes('requesterOnAssignmentTripTypes', 'selectAllRequesterOnAssignmentTripTypes')
                        "
                      ></v-checkbox>
                      <v-checkbox
                        v-for="(type, i) in tripTypes"
                        :key="i"
                        class="checkbox"
                        :value="type.id"
                        v-model="config.requesterOnAssignmentTripTypes"
                        :label="type.name"
                        multiple
                        @click="
                          handleTripTypeSelected(
                            'requesterOnAssignmentTripTypes',
                            'selectAllRequesterOnAssignmentTripTypes'
                          )
                        "
                      ></v-checkbox>
                    </div>
                  </v-col>
                </v-row>
                <v-row class="pa-2 section" dense>
                  <v-col cols="12" md="3">
                    <v-subheader class="px-0">
                      Notify vehicle drivers (and assistants if used) when vehicle/driver assignment has been made?
                    </v-subheader>
                    <div class="checkbox-wrapper">
                      <v-checkbox
                        v-for="(method, i) in [
                          { value: 'email', label: 'By Email?' },
                          { value: 'text', label: 'By Text?' },
                        ]"
                        :key="i"
                        v-model="config.driverOnAssignment"
                        :value="method.value"
                        :label="method.label"
                        class="checkbox"
                        multiple
                      ></v-checkbox>
                    </div>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-subheader class="px-0">Additional custom text to append to driver email</v-subheader>
                    <v-textarea v-model="config.driverOnAssignmentMessage" outlined rows="1" auto-grow></v-textarea>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-subheader class="px-0">Additional custom text to append to assistant email</v-subheader>
                    <v-textarea v-model="config.assistantOnAssignmentMessage" outlined rows="1" auto-grow></v-textarea>
                  </v-col>
                  <v-col cols="12" md="3" v-show="me.is.superAdmin">
                    <v-subheader class="px-0">
                      What time should driver/assistant notifications be sent out? (in seconds)
                    </v-subheader>
                    <v-text-field
                      v-model.trim="config.driverOnAssignmentTime"
                      type="number"
                      min="0"
                      hint="66900 = 6:35pm"
                      persistent-hint
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="pa-2 section" dense v-if="false">
                  <v-col cols="12" md="4">
                    <v-subheader class="pl-0"> Exclude link to access assignments in driver emails? </v-subheader>
                    <v-radio-group row v-model="config.excludeLinks">
                      <v-radio :value="true">
                        <template v-slot:label> Yes </template>
                      </v-radio>
                      <v-radio :value="false">
                        <template v-slot:label> No </template>
                      </v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
                <v-row class="pa-2 section" dense>
                  <v-col cols="12" md="4">
                    <v-subheader class="pl-0"> Include links for Drivers to accept or decline assignment? </v-subheader>
                    <v-radio-group
                      row
                      v-model="config.includeAcceptDeclineLink"
                      :disabled="!driverConfig.useAvailableDrivers"
                    >
                      <v-radio :value="true">
                        <template v-slot:label> Yes </template>
                      </v-radio>
                      <v-radio :value="false">
                        <template v-slot:label> No </template>
                      </v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
                <v-row class="pa-2 section" dense>
                  <v-col cols="12" md="6">
                    <v-subheader class="px-0">Notify teacher when assignment is made?</v-subheader>
                    <v-radio-group v-model="config.teacherOnAssignmentMade" row>
                      <v-radio label="Yes" :value="true"></v-radio>
                      <v-radio label="No" :value="false"></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header>
                <div class="header-text">Scheduled</div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row class="pa-2 section" dense>
                  <v-col cols="12" md="3">
                    <v-subheader class="px-0">Notify Requestor Day Before Trip</v-subheader>
                    <v-radio-group v-model="config.requesterDayBefore" row>
                      <v-radio label="Yes" :value="true"></v-radio>
                      <v-radio label="No" :value="false"></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="12" md="5">
                    <v-subheader class="px-0">Additional custom text to append to standard email</v-subheader>
                    <v-textarea v-model="config.requesterDayBeforeMessage" outlined rows="1" auto-grow></v-textarea>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-subheader>Trip Types</v-subheader>
                    <div class="checkbox-wrapper">
                      <v-checkbox
                        v-model="selectAllRequesterDayBeforeTripTypes"
                        class="checkbox"
                        label="All"
                        @change="handleAllTypes('requesterDayBeforeTripTypes', 'selectAllRequesterDayBeforeTripTypes')"
                      ></v-checkbox>
                      <v-checkbox
                        v-for="(type, i) in tripTypes"
                        :key="i"
                        class="checkbox"
                        :value="type.id"
                        v-model="config.requesterDayBeforeTripTypes"
                        :label="type.name"
                        multiple
                        @click="
                          handleTripTypeSelected('requesterDayBeforeTripTypes', 'selectAllRequesterDayBeforeTripTypes')
                        "
                      ></v-checkbox>
                    </div>
                  </v-col>
                </v-row>
                <v-row class="pa-2 section" dense>
                  <v-col cols="12" md="3">
                    <v-subheader class="px-0">Notify Requestor Day After Trip</v-subheader>
                    <v-radio-group v-model="config.requesterDayAfter" row>
                      <v-radio label="Yes" :value="true"></v-radio>
                      <v-radio label="No" :value="false"></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="12" md="5">
                    <v-subheader class="px-0">Additional custom text to append to standard email</v-subheader>
                    <v-textarea v-model="config.requesterDayAfterMessage" outlined rows="1" auto-grow></v-textarea>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-subheader class="px-0">Trip Types</v-subheader>
                    <div class="checkbox-wrapper">
                      <v-checkbox
                        v-model="selectAllRequesterDayAfterTripTypes"
                        class="checkbox"
                        label="All"
                        @change="handleAllTypes('requesterDayAfterTripTypes', 'selectAllRequesterDayAfterTripTypes')"
                      ></v-checkbox>
                      <v-checkbox
                        v-for="(type, i) in tripTypes"
                        :key="i"
                        class="checkbox"
                        :value="type.id"
                        v-model="config.requesterDayAfterTripTypes"
                        :label="type.name"
                        multiple
                        @click="
                          handleTripTypeSelected('requesterDayAfterTripTypes', 'selectAllRequesterDayAfterTripTypes')
                        "
                      ></v-checkbox>
                    </div>
                  </v-col>
                </v-row>
                <v-row class="pa-2 section" dense>
                  <v-col cols="12" md="3">
                    <v-subheader class="px-0"> Notify driver by email day before trip? </v-subheader>
                    <div class="checkbox-wrapper">
                      <v-checkbox
                        v-for="(method, i) in [
                          { value: 'email', label: 'By Email?' },
                          { value: 'text', label: 'By Text?' },
                        ]"
                        :key="i"
                        v-model="config.driverDayBefore"
                        :value="method.value"
                        :label="method.label"
                        class="checkbox"
                        multiple
                      ></v-checkbox>
                    </div>
                  </v-col>
                  <v-col cols="12" md="5">
                    <v-subheader class="px-0">Additional custom text to append to standard email</v-subheader>
                    <v-textarea v-model="config.driverDayBeforeMessage" outlined rows="1" auto-grow></v-textarea>
                  </v-col>
                </v-row>
                <v-row class="pa-2 section" dense>
                  <v-col cols="12" md="3">
                    <v-subheader class="px-0"
                      >Notify Child Nutrition of students away during lunch 4 days before trip?</v-subheader
                    >
                    <v-radio-group v-model="config.nutritionistFourDaysBefore" row>
                      <v-radio label="Yes" :value="true"></v-radio>
                      <v-radio label="No" :value="false"></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="12" md="5">
                    <v-subheader class="px-0">Additional custom text to append to standard email</v-subheader>
                    <v-textarea
                      v-model="config.nutritionistFourDaysBeforeMessage"
                      outlined
                      rows="1"
                      auto-grow
                    ></v-textarea>
                  </v-col>
                </v-row>
                <v-row class="pa-2 section" dense>
                  <v-col cols="12" md="3">
                    <v-subheader class="px-0">Notify charter companies 7 days before trip?</v-subheader>
                    <v-radio-group v-model="config.charterSevenDaysBefore" row>
                      <v-radio label="Yes" :value="true"></v-radio>
                      <v-radio label="No" :value="false"></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-subheader class="px-0">Notify contractor 7 days before trip?</v-subheader>
                    <v-radio-group v-model="config.contractorSevenDaysBefore" row>
                      <v-radio label="Yes" :value="true"></v-radio>
                      <v-radio label="No" :value="false"></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
                <v-row class="pa-2 section" dense v-if="false">
                  <v-col cols="12" md="3">
                    <v-subheader class="px-0"
                      >Send weekly email to drivers listing their upcoming assignments for next two weeks?</v-subheader
                    >
                    <div class="checkbox-wrapper">
                      <v-checkbox
                        v-for="(method, i) in [
                          { value: 'email', label: 'By Email?' },
                          { value: 'text', label: 'By Text?' },
                        ]"
                        :key="i"
                        v-model="config.driverWeekly"
                        :value="method.value"
                        :label="method.label"
                        class="checkbox"
                        multiple
                      ></v-checkbox>
                    </div>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header>
                <div class="header-text">Mechanics</div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row class="pa-2 section" dense>
                  <v-col cols="12" md="3">
                    <v-subheader class="px-0"> Notify mechanic by email 3 days before trip? </v-subheader>
                    <v-radio-group v-model="config.mechanicThreeDaysBefore" row>
                      <v-radio label="Yes" :value="true"></v-radio>
                      <v-radio label="No" :value="false"></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
                <v-row class="pa-2 section" dense>
                  <v-col cols="12" md="8">
                    <user-select
                      :value="config.notifyOnVehicleComments"
                      v-model="config.notifyOnVehicleComments"
                      :users="users"
                      :multiple="false"
                      label="Email of person to be notified about comments or problems concerning a vehicle"
                      @handleUserSelect="handleUserSelect($event, 'notifyOnVehicleComments')"
                    >
                    </user-select>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header>
                <div class="header-text">Invoice</div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row class="pa-2 section" dense>
                  <v-col cols="12" md="6">
                    <v-subheader class="px-0"
                      >Send weekly emails to staff responsible for payment who have not confirmed "Payment Sent" on
                      completed trips.</v-subheader
                    >
                    <v-radio-group v-model="config.weeklyEmailSchoolPersonPaymentReminder" row>
                      <v-radio label="Yes" :value="true"></v-radio>
                      <v-radio label="No" :value="false"></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
                <v-row class="pa-2 section" dense>
                  <v-col cols="12" md="6">
                    <v-subheader class="px-0"
                      >Send weekly emails to funding payment or funding approver staff who have not confirmed "Payment
                      Sent" on completed trips.</v-subheader
                    >
                    <v-radio-group v-model="config.weeklyEmailFundingPersonPaymentReminder" row>
                      <v-radio label="Yes" :value="true"></v-radio>
                      <v-radio label="No" :value="false"></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
      </v-card>
    </v-form>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { GET_CONFIG, SET_CONFIG } from '@/store/modules/Config/actions';
import UserSelect from '@/components/UserSelect.vue';
import promptBeforeLeave from '@/mixins/promptBeforeLeave';

export default {
  mixins: [promptBeforeLeave],
  inject: ['eventHub'],
  components: { UserSelect },
  data: () => ({
    config: {
      requesterOnDeny: false,
      onReschedule: false,
      onRescheduleAdditional: [],
      onRescheduleRequester: true,
      onRescheduleApprovers: true,
      onRescheduleNurse: true,
      onRescheduleTeacher: true,
      onRescheduleContractor: true,
      onRescheduleSchoolFinance: true,
      onRescheduleVehicleOwner: true,
      onRescheduleNutritionist: true,
      onRescheduleDestination: true,
      onRescheduleTransportation: true,
      onRescheduleOrCancelAssignment: [],
      onRescheduleOrCancelAssignmentDriver: true,
      onRescheduleOrCancelAssignmentAssistant: true,
      onRescheduleOrCancelAssignmentVehicleOwner: true,
      requesterOnApproval: false,
      requesterOnApprovalMessage: '',
      requesterOnApprovalTripTypes: [],
      siteAdminOnApproval: false,
      nurseOnApproval: false,
      nurseOnApprovalTrips: 'healthConcerns',
      nurseOnApprovalTripTypes: [],
      nutritionistOnApproval: false,
      nutritionistOnApprovalMessage: '',
      financeOnApproval: false,
      schoolFinanceOnApproval: false,
      charterOnApproval: false,
      charterIncludeLink: false,
      contractorOnApproval: false,
      contractorIncludeLink: false,
      rentalDealershipOnApproval: false,
      rentalDealershipIncludeLink: false,
      adminOnAutoApprove: true,
      vehicleOwnerCanAssign: false,
      vehicleOwnerOnSelfDecline: false,
      requesterOnAssignment: false,
      requesterOnAssignmentMessage: '',
      requesterOnAssignmentTripTypes: [],
      driverOnAssignment: [],
      driverOnAssignmentMessage: '',
      assistantOnAssignmentMessage: '',
      driverOnAssignmentTime: 18 * 3600 + 35 * 60, // 6:35 PM in seconds
      requesterDayBefore: false,
      requesterDayBeforeMessage: '',
      requesterDayBeforeTripTypes: [],
      requesterDayAfter: false,
      requesterDayAfterMessage: '',
      requesterDayAfterTripTypes: [],
      driverDayBefore: [],
      driverDayBeforeMessage: '',
      includeAcceptDeclineLink: false,
      nutritionistFourDaysBefore: false,
      nutritionistFourDaysBeforeMessage: '',
      charterSevenDaysBefore: false,
      mechanicThreeDaysBefore: false,
      contractorSevenDaysBefore: false,
      driverWeekly: [],
      excludeLinks: false,
      weeklyEmailSchoolPersonPaymentReminder: false,
      weeklyEmailFundingPersonPaymentReminder: false,
      notifyOnVehicleComments: [],
      teacherOnApproval: false,
      teacherOnAssignmentMade: false,
    },
    selectAllRequesterOnApprovalTripTypes: false,
    selectAllNurseOnApprovalTripTypes: false,
    selectAllRequesterOnAssignmentTripTypes: false,
    selectAllRequesterDayBeforeTripTypes: false,
    selectAllRequesterDayAfterTripTypes: false,
    driverConfig: {},
  }),
  computed: {
    ...mapGetters('user', ['me', 'users', 'usersById']),
    ...mapGetters('tripType', ['tripTypes']),
    ...mapGetters('vehicleType', ['vehicleTypes']),
  },
  async mounted() {
    await this.fetchItems();
  },
  methods: {
    ...mapActions('config', [GET_CONFIG, SET_CONFIG]),
    ...mapActions('user', ['getUsers']),
    async fetchItems() {
      this.notfyConfig = await this.getConfig('notify');
      if (this.notfyConfig) this.config = { ...this.config, ...this.notfyConfig };
      this.baseFormValue = { ...this.config };
      this.checkAllTripTypeFields();
      this.driverConfig = await this.getConfig('driver');
      await this.getUsers();
    },
    async save() {
      try {
        const r = await this.setConfig({ name: 'notify', value: this.config });
        if (r && r.done) {
          this.baseFormValue = { ...this.config };
          this.$myalert.success('Notification settings saved');
        }
      } catch (error) {
        this.$myalert.error(error.message);
      }
    },
    handleUserSelect(users, field) {
      if (field === 'notifyOnVehicleComments') {
        this.config.notifyOnVehicleComments = this.usersById[users] ? this.usersById[users]?.email : users;
        return;
      }
      const emails = users.map((e) => {
        if (typeof e == 'number') {
          if (this.usersById[e]) return this.usersById[e].email;
          else return null;
        } else return e;
      });
      if (field == 'onRescheduleAdditional') this.config.onRescheduleAdditional = emails.filter((e) => e);
    },
    handleAllTypes(field, all) {
      if (this.config[field].length == this.tripTypes.length) this.config[field] = [];
      else this.config[field] = this.tripTypes.map((e) => e.id);
      this.handleTripTypeSelected(field, all);
    },
    handleTripTypeSelected(field, all) {
      if (this.config[field].length == this.tripTypes.length) this[all] = true;
      else this[all] = false;
    },
    checkAllTripTypeFields() {
      const check = [
        { field: 'requesterOnApprovalTripTypes', all: 'selectAllRequesterOnApprovalTripTypes' },
        { field: 'nurseOnApprovalTripTypes', all: 'selectAllNurseOnApprovalTripTypes' },
        { field: 'requesterOnAssignmentTripTypes', all: 'selectAllRequesterOnAssignmentTripTypes' },
        { field: 'requesterDayBeforeTripTypes', all: 'selectAllRequesterDayBeforeTripTypes' },
        { field: 'requesterDayAfterTripTypes', all: 'selectAllRequesterDayAfterTripTypes' },
      ];
      for (let c of check) this.handleTripTypeSelected(c.field, c.all);
    },
  },
  created() {
    this.eventHub.$on('saveNotifications', () => this.save());
  },
  beforeDestroy() {
    this.eventHub.$off('saveNotifications');
  },
  watch: {
    'driverConfig.useAvailableDrivers'(value) {
      if (!value) {
        this.config.includeAcceptDeclineLink = false;
      }
    },
    config: {
      handler(value) {
        this.latestFormValue = { ...value };
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.save-btn {
  float: right;
  margin-bottom: 12px;
}
.bg-blue {
  background-color: aliceblue;
}
.inline {
  display: inline-block;
}
.text-field {
  max-width: 100px;
}
.checkbox-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center !important;
}
.checkbox {
  margin-right: auto;
}
.section:nth-child(odd) {
  background-color: aliceblue;
}
.header-text {
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.0125em;
  line-height: 2rem;
  word-break: break-all;
}
</style>
